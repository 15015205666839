import {useIntl} from "gatsby-plugin-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Formulario from "../components/formulario";
import {
    cerrado,
    costosHorizontes,
    detallesHorizontes, feriados,
    horasHorizontes,
} from "../components/defaults";
import React from "react";

const Horizontes = () => {
    const sectorACG = "Horizontes";
    const intl = useIntl();
    const fechasCerrado = cerrado[sectorACG];
    return (
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({id: "hz.titulo"})}
                 description={intl.formatMessage({id: "hz.info1"})}
                 image={"/images/horizontes-cover.jpg"}
            />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({id: "EEFH"})}
                    </Typography>
                    <Typography variant={"body2"}>
                        {intl.formatMessage({id: "hz.info1"})} <a href={"https://www.acguanacaste.ac.cr/turismo/sector-horizontes"} target={"_blank"}>{intl.formatMessage({id: "EEFH"})}</a> {intl.formatMessage({id: "hz.info3"})}
                    </Typography>
                </Grid>
            </Grid>
            <Formulario
                sector ={sectorACG}
                horas = {horasHorizontes}
                inputFields =  {detallesHorizontes}
                costos = {costosHorizontes}
                pago = "Transferencia"
                diasPevios = {1}
                diaCerrado = {[-1]}
                cerrado = {fechasCerrado}
                iva = {0}
                feriados = {feriados}
            />
        </Layout>
    )
}

export default Horizontes